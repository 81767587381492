import { Environment } from '@shared-libs/enums';

export const environment = {
	BASE_URL: 'https://api.beego.be',
	ONESIGNAL: 'fdb1acca-b73b-4418-b722-5059a8609a9b',
	production: true,
	environment: Environment.Production,
	sentryDsn: 'https://68dce7d9913642b1bdc3b0e747c20687@sentry.beego.be/5',
	eventEngineUrl: 'wss://ws.event-engine.prod.beego.be',
};
